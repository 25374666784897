import * as React from 'react';

import Frame from '../components/Frame';
import * as styles from './404.module.scss';

const NotFoundPage: React.FunctionComponent = () => (
  <Frame className={styles.notFoundPage}>404 not found</Frame>
);

export default NotFoundPage;
